<template>
    <div class="p-d-flex  p-flex-column" style="width:100%; height: 100%; position: absolute;" >
        <div class=" p-as-center" style="display: table;height:100%;">
            <div style="margin: 0 auto; display: table-cell;   vertical-align: middle;width: 100%; ">
                <div class="">
                    <div>
                        <div class="p-text-center loginLogoMini">{{$t('utils.notFoundFormText')}}</div>
                    </div>
                    <div>
                        <div class="p-text-center loginLogoMini" style="word-break: break-word; white-space: pre-line;">{{$t('utils.notFoundFormText2', {LINK: $route.query.link})}}</div>
                    </div>
                </div>
            </div>
        </div>
        <BankInvoicePaperFooter />
    </div>
</template>

<script>
import BankInvoicePaperFooter from '@/components/BankInvoicePaperFooter';

export default {
    name: 'NotFoundForms.vue',
    components: {
        BankInvoicePaperFooter,
    },
    data: function(){
        return {
        }
    },
    computed: {
    },
    methods: {
    }
}
</script>

<style scoped>
.loginDocumentText {
    text-align: center;
    font-size: 46pt;
    font-style: normal;
    font-weight: 300;
    color: #666666;
    /* font-family: 'MS P明朝',courier,'SF Pro Icons','Helvetica Neue',Helvetica,Arial,sans-serif; */
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    max-width: 100%;
}
</style>

